.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 가로 가운데 정렬 */
  align-items: center; /* 세로 가운데 정렬 */
  width: 100%; /* 부모 요소 너비에 맞춤 */
  /* height: 100vh; 화면 높이에 맞춤 */
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.visible {
  opacity: 1;
}

.hidden {
  display: none;
}

.contents {
  margin-top: 3.5em;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
