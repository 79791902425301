.login_outer {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
}

.login_inner {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 400px;
}

.login_title {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    font-weight: 600;
    font-size: 27px;
    color: #343A40;
    gap: 10px;
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 42px;
    box-sizing: border-box;
    /* 박스 모델을 변경하여 패딩을 포함한 너비 계산 */
    width: 100%;
    margin: 1em auto 3em auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.06);
}

.login input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1em;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, .05);
    background-color: #fff;
    border-radius: 4px;
    color: #363636;
    border: 1px solid #dbdbdb;
    /* 이걸 해야 경계선 색이 같아진다. */
    font-size: 1rem;
    padding: 8px 12px;
}

.login button {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 0;
    background-color: #ff7133;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    font-size: 1rem;
    margin-top: 1.5em;
}

#msg_box {
    display: none;
    width: 100%;
    color: #ff5252 !important;
    border: #ff5252 solid thin;
    margin: 10px 5px;
    padding: 0.5em;
    font-size: 13px;
    font-weight: 600;
    border-radius: 4px;
}