.container {
}

.history {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.history td,
.history th {
  border: 1px solid #ddd;
  padding: 8px;
}

.history tr:nth-child(even) {
  background-color: #f2f2f2;
}

.history tr:hover {
  background-color: #ddd;
}

.history th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 반투명한 배경색 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 8px;
  /* max-width: 400px;
  width: 100%; */
}

.modalTop {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modalInner {
  display: flex;
  width: 100%;
  padding: 10px;
}

.modalBtm {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.modalBtn {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #04aa6d;
  font-weight: bold;
  color: white;
}

.close {
  font-size: 20px;
  cursor: pointer;
}

.contentHeader {
  display: flex;
  gap: 10px;
}

.msg {
  color: red;
}
